import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './website/HomePage';
import ElementarySchoolMath from './website/courses/math/elementary/ElementarySchoolMath';
import MiddleSchoolMath from './website/courses/math/middle/MiddleSchoolMath';
import HighSchoolMath from './website/courses/math/high/HighSchoolMath';
import CollegeMath from './website/courses/math/college/CollegeMath';
import YoungLearnersA from './website/courses/math/elementary/YoungLearnersA';
import YoungLearnersB from './website/courses/math/elementary/YoungLearnersB';
import JuniorScholarsA from './website/courses/math/elementary/JuniorScholarsA';
import JuniorScholarsB from './website/courses/math/elementary/JuniorScholarsB';
import PreAlgebraA from './website/courses/math/middle/PreAlgebraA'
import PreAlgebraB from './website/courses/math/middle/PreAlgebraB'
import Algebra1A from './website/courses/math/middle/Algebra1A';
import Algebra1B from './website/courses/math/middle/Algebra1B';
import GeometryA from './website/courses/math/middle/GeometryA';
import GeometryB from './website/courses/math/middle/GeometryB';
import Algebra2A from './website/courses/math/high/Algebra2A';
import Algebra2B from './website/courses/math/high/Algebra2B';
import PreCalTrigA from './website/courses/math/high/PreCalTrigA';
import PreCalTrigB from './website/courses/math/high/PreCalTrigB';
import ApCalculus from './website/courses/math/high/ApCalculus';
import Scratch from './website/courses/coding/scratch/Scratch';
import ScratchLevelA from './website/courses/coding/scratch/ScratchLevelA';
import ScratchLevelB from './website/courses/coding/scratch/ScratchLevelB';
import Python from './website/courses/coding/python/Python';
import PythonLevelA from './website/courses/coding/python/PythonLevelA';
import PythonLevelB from './website/courses/coding/python/PythonLevelB';
import PythonLevelC from './website/courses/coding/python/PythonLevelC';
import PyGame from './website/courses/coding/python/PyGame';
import DataScience from './website/courses/coding/python/DataScience';
import AiLevelA from './website/courses/coding/python/AiLevelA';
import AiLevelB from './website/courses/coding/python/AiLevelB';
import Java from './website/courses/coding/java/Java';
import JavaLevelA from './website/courses/coding/java/JavaLevelA';
import JavaLevelB from './website/courses/coding/java/JavaLevelB';
import JavaLevelC from './website/courses/coding/java/JavaLevelC';
import WebDevelopment from './website/courses/coding/web-development/WebDevelopment';
import JavaScriptLevelA from './website/courses/coding/web-development/JavascriptLevelA';
import JavaScriptLevelB from './website/courses/coding/web-development/JavaScriptLevelB';
import AI from './website/courses/coding/ai/AI';
import Cpp from './website/courses/coding/Cpp';
import MultiStepForm from './website/common/MultiStepForm';

function AppRouter(){

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/elementary-school-math" element={<ElementarySchoolMath/>} />
        <Route path="/middle-school-math" element={<MiddleSchoolMath/>} />
        <Route path="/high-school-math" element={<HighSchoolMath/>} />
        <Route path="/college-math" element={<CollegeMath/>} />
        <Route path="/courses/young-learners-a/" element={<YoungLearnersA/>} />
        <Route path="/courses/young-learners-b/" element={<YoungLearnersB/>} />
        <Route path="/courses/junior-scholars-a/" element={<JuniorScholarsA/>} />
        <Route path="/courses/junior-scholars-b/" element={<JuniorScholarsB/>} />
        <Route path="/courses/pre-algebra-a/" element={<PreAlgebraA/>} />
        <Route path="/courses/pre-algebra-b/" element={<PreAlgebraB/>} />
        <Route path="/courses/algebra-1a/" element={<Algebra1A/>} />
        <Route path="/courses/algebra-1b/" element={<Algebra1B/>} />
        <Route path="/courses/algebra-2a/" element={<Algebra2A/>} />
        <Route path="/courses/algebra-2b/" element={<Algebra2B/>} />
        <Route path="/courses/geometry-a/" element={<GeometryA/>} />
        <Route path="/courses/geometry-b/" element={<GeometryB/>} />
        <Route path="/courses/pre-calculus-trigonometry-a/" element={<PreCalTrigA/>} />
        <Route path="/courses/pre-calculus-trigonometry-b/" element={<PreCalTrigB/>} />
        <Route path="/courses/ap-calculus/" element={<ApCalculus/>} />
        <Route path="/scratch" element={<Scratch/>} />
        <Route path="/courses/scratch-level-a/" element={<ScratchLevelA/>} />
        <Route path="/courses/scratch-level-b/" element={<ScratchLevelB/>} />
        <Route path="/python" element={<Python/>} />
        <Route path="/courses/python-level-a/" element={<PythonLevelA/>} />
        <Route path="/courses/python-level-b/" element={<PythonLevelB/>} />
        <Route path="/courses/python-level-c/" element={<PythonLevelC/>} />
        <Route path="/courses/pygame/" element={<PyGame/>} />
        <Route path="/courses/data-science/" element={<DataScience/>} />
        <Route path="/courses/ai-level-a/" element={<AiLevelA/>} />
        <Route path="/courses/ai-level-b/" element={<AiLevelB/>} />
        <Route path="/java" element={<Java/>} />
        <Route path="/courses/java-level-a/" element={<JavaLevelA/>} />
        <Route path="/courses/java-level-b/" element={<JavaLevelB/>} />
        <Route path="/courses/java-level-c/" element={<JavaLevelC/>} />
        <Route path="/web-development" element={<WebDevelopment/>} />
        <Route path="/courses/javascript-level-a/" element={<JavaScriptLevelA/>} />
        <Route path="/courses/javascript-level-b/" element={<JavaScriptLevelB/>} />
        <Route path="/artificial-intelligence" element={<AI/>} />
        <Route path="/c-plus-plus-fundamentals" element={<Cpp/>} />
        <Route path="/get-free-class" element={<MultiStepForm />} />
      </Routes>
    </Router>
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppRouter/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
